import { debounce } from '../js/global-exports.js';

class PredictiveSearch extends HTMLElement {
  constructor() {
    super();
    // only show in header
    if(this.closest('.header__search')) {
      this.cachedResults = {};
      this.input = this.querySelector('input[type="search"]');
      this.predictiveSearchResults = this.querySelector('[data-predictive-search]');
      this.maxResults = this.getAttribute('data-max-results');

      this.setupEventListeners();
    }
  }

  setupEventListeners() {
    const form = this.querySelector('form.search');
    form.addEventListener('submit', this.onFormSubmit.bind(this));

    this.input.addEventListener('input', debounce((event) => {
      this.onChange(event);
    }, 300).bind(this));
    this.input.addEventListener('focus', this.onFocus.bind(this));

    this.addEventListener('keyup', this.onKeyup.bind(this));
    this.addEventListener('keydown', this.onKeydown.bind(this));

    this.addEventListener('toggle', this.onToggle.bind(this), {capture: true})
  }

  onToggle(e) {
    const detailsOpened = e.target.open;

    if(detailsOpened) {
      document.body.classList.add('overflow-hidden')
      const allDetails = this.querySelectorAll('details');

      allDetails.forEach((elem)=>{
        if(e.target !== elem) {
          elem.open = false;
        }
      });

    }else{
      document.body.classList.remove('overflow-hidden')
    }
  }

  getQuery() {
    return this.input.value.trim();
  }

  onChange() {
    const searchTerm = this.getQuery();

    if (!searchTerm.length) {
      // show predefined results
      this.showPredefinedSearchResults();
      return;
    }

    this.getSearchResults(searchTerm);
  }

  onFormSubmit(event) {
    if (!this.getQuery().length || this.querySelector('[aria-selected="true"] a')) event.preventDefault();
  }

  onFocus() {
    const searchTerm = this.getQuery();

    if (!searchTerm.length) {
      const searchTerm = this.getQuery();
      if (!searchTerm.length) {
        // show predefined results
        this.showPredefinedSearchResults();
      }
    } else {
      if (this.getAttribute('results') === 'true') {
        this.open();
      } else {
        this.getSearchResults(searchTerm);
      }
    }
  }

  showPredefinedSearchResults(showNoResultsText) {
    const templatePredefinedResults = document.getElementById('predictive-search-predefined');
    if(!templatePredefinedResults) {return;}
    templatePredefinedResults
    const predefinedResultsMarkup = templatePredefinedResults.content.cloneNode(true);
    if(showNoResultsText === true) {
      const templateNoResults = document.getElementById('predictive-search-no-results');
      const noResultsMarkup = templateNoResults.content.cloneNode(true);
      // predefinedResultsMarkup.getElementById('predictive-search-results-list').prepend(noResultsMarkup);// if predefined results needs to be shown below
      this.renderSearchResults(noResultsMarkup);
    } else {
      this.setLiveRegionLoadingState();
      this.renderSearchResults(predefinedResultsMarkup);
    }
  }

  onKeyup(event) {
    if (!this.getQuery().length) {
      this.close(true);
    }
    event.preventDefault();

    switch (event.code) {
      case 'ArrowUp':
        this.switchOption('up')
        break;
      case 'ArrowDown':
        this.switchOption('down');
        break;
      case 'Enter':
        this.selectOption();
        break;
    }
  }

  onKeydown(event) {
    // Prevent the cursor from moving in the input when using the up and down arrow keys
    if (
      event.code === 'ArrowUp' ||
      event.code === 'ArrowDown'
    ) {
      event.preventDefault();
    }
  }

  switchOption(direction) {
    if (!this.getAttribute('open')) return;

    const moveUp = direction === 'up';
    const selectedElement = this.querySelector('[aria-selected="true"]');
    const allElements = this.querySelectorAll('li');
    let activeElement = this.querySelector('li');

    if (moveUp && !selectedElement) return;

    this.statusElement.textContent = '';

    if (!moveUp && selectedElement) {
      activeElement = selectedElement.nextElementSibling || allElements[0];
    } else if (moveUp) {
      activeElement = selectedElement.previousElementSibling || allElements[allElements.length - 1];
    }

    if (activeElement === selectedElement) return;

    activeElement.setAttribute('aria-selected', true);
    if (selectedElement) selectedElement.setAttribute('aria-selected', false);

    this.setLiveRegionText(activeElement.textContent);
    this.input.setAttribute('aria-activedescendant', activeElement.id);
  }

  selectOption() {
    const selectedProduct = this.querySelector('[aria-selected="true"] a, [aria-selected="true"] button');

    if (selectedProduct) selectedProduct.click();
  }

  getSearchResults(searchTerm) {
    const queryKey = searchTerm.replace(' ', '-').toLowerCase();
    this.setLiveRegionLoadingState();

    if (this.cachedResults[queryKey]) {
      this.renderSearchResults(this.cachedResults[queryKey]);
      return;
    }

    fetch(`${routes.predictive_search_url}?q=${encodeURIComponent(searchTerm)}&${encodeURIComponent('resources[type]')}=product,page,article,collection,query&${encodeURIComponent('resources[limit_scope]')}=each&${encodeURIComponent('resources[limit]')}=${parseInt(this.maxResults, 10)}&section_id=predictive-search`)
      .then((response) => {
        if (!response.ok) {
          var error = new Error(response.status);
          this.close();
          throw error;
        }

        return response.text();
      })
      .then((text) => {
        let resultsMarkup = new DOMParser().parseFromString(text, 'text/html').querySelector('#shopify-section-predictive-search').innerHTML;
        this.cachedResults[queryKey] = resultsMarkup;

        if(resultsMarkup.search('data-zero-results') === -1) { // there are results
          this.renderSearchResults(resultsMarkup);
        }else{
          this.showPredefinedSearchResults(true);
        }
      })
      .catch((error) => {
        this.close();
        throw error;
      });
  }

  setLiveRegionLoadingState() {
    this.statusElement = this.statusElement || this.querySelector('.predictive-search-status');
    this.loadingText = this.loadingText || this.getAttribute('data-loading-text');

    this.setLiveRegionText(this.loadingText);
    this.setAttribute('loading', true);
  }

  setLiveRegionText(statusText) {
    this.statusElement.setAttribute('aria-hidden', 'false');
    this.statusElement.textContent = statusText;

    setTimeout(() => {
      this.statusElement.setAttribute('aria-hidden', 'true');
    }, 1000);
  }

  renderSearchResults(resultsMarkup) {
    if(typeof resultsMarkup === 'string') {
      this.predictiveSearchResults.innerHTML = resultsMarkup;
    } else{
      this.predictiveSearchResults.innerHTML = '';
      this.predictiveSearchResults.appendChild(resultsMarkup);
    }
    this.setAttribute('results', true);

    if(typeof resultsMarkup === 'string' && resultsMarkup.search('data-zero-results') === -1) { // there are results
      this.setLiveRegionResults();
    }
    this.open();
  }

  setLiveRegionResults() {
    this.removeAttribute('loading');
    this.setLiveRegionText(this.querySelector('[data-predictive-search-live-region-count-value]').textContent);
  }

  open() {
    this.setAttribute('open', true);
    this.input.setAttribute('aria-expanded', true);
  }

  close(clearSearchTerm = false) {
    if (clearSearchTerm) {
      this.input.value = '';
      this.removeAttribute('results');
    }

    const selected = this.querySelector('[aria-selected="true"]');

    if (selected) selected.setAttribute('aria-selected', false);

    this.input.setAttribute('aria-activedescendant', '');
    this.removeAttribute('open');
    this.input.setAttribute('aria-expanded', false);
    this.resultsMaxHeight = false
    this.predictiveSearchResults.removeAttribute('style');
  }
}

customElements.define('predictive-search', PredictiveSearch);
